export default {
  items: [
    {
      title: true,
      name: 'Products',
      class: '',
      permission: ["full_admin"],
      wrapper: {
        element: '',
        attributes: {}
      }
    },
    {
        name: "Household",
        url: '/system-config/products/2',
        icon: 'icon-home',
        permission: ["full_admin"],
        area: 'system_config',
        children: [
          {
            name: 'Insurers',
            url: '/system-config/products/2/insurers',
            icon: 'icon-layers',
            permission: ["full_admin",],
          },
          {
            name: 'Addons',
            url: '/system-config/products/2/addons',
            icon: 'icon-layers',
            permission: ["full_admin",],
          },
          {
            name: 'Endorsements',
            url: '/system-config/products/2/endorsements',
            icon: 'icon-layers',
            permission: ["full_admin",],
          },
          {
            name: 'Eligibility Question',
            url: '/system-config/products/2/eligibility-questions/',
            icon: 'icon-question',
            permission: ["full_admin",],
          },
          {
            name: 'Commission (%)',
            url: '/system-config/products/2/commission',
            icon: 'icon-list',
            permission: ["full_admin",],
          },
          {
            name: 'Commission Panels',
            url: '/system-config/products/2/commissionpanels',
            icon: 'icon-grid',
            permission: ["full_admin",],
          },
          {
            name: 'Rates',
            url: '/system-config/products/2/rates',
            icon: 'icon-calculator',
            permission: ["full_admin",],
          },
        ]
      },
     {
        name: "Non Standard",
        url: '/system-config/products/1',
        icon: 'icon-home',
        permission: ["full_admin"],
        area: 'system_config',
        children: [
          {
            name: 'Insurers',
            url: '/system-config/products/1/insurers',
            icon: 'icon-layers',
            permission: ["full_admin",],
          },
          {
            name: 'Addons',
            url: '/system-config/products/1/addons',
            icon: 'icon-layers',
            permission: ["full_admin",],
          },
          {
            name: 'Endorsements',
            url: '/system-config/products/1/endorsements',
            icon: 'icon-layers',
            permission: ["full_admin",],
          },
          {
            name: 'Eligibility Question',
            url: '/system-config/products/1/eligibility-questions/',
            icon: 'icon-question-mark',
            permission: ["full_admin",],
          },
          {
            name: 'Commission',
            url: '/system-config/products/1/commission',
            icon: 'icon-layers',
            permission: ["full_admin",],
          },
          /*{
            name: 'Rates',
            url: '/system-config/products/1/rates',
            icon: 'icon-calculator',
            permission: ["full_admin",],
          },*/
        ]
      },
      {
        divider: true,
        permission: ["full_admin",],
      },
      {
        title: true,
        name: 'System',
        class: '',
        permission: ["full_admin"],
        wrapper: {
          element: '',
          attributes: {}
        }
      },
      {
        name: 'Users',
        url: '/system-config/users',
        icon: 'icon-user',
        permission: ["full_admin",],
      },
      {
        name: 'Email Settings',
        url: '/system-config/email-settings',
        icon: 'icon-envelope',
        permission: ["full_admin",],
      },
  ]
}
