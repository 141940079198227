export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
      permission: ["full_admin", 'data_management', 'customer_services',],
      permission_name: ["backoffice.dashboard.view"],
    },
    {
      name: 'Customer Service',
      url: '/customer-service',
      icon: 'icon-list',
      permission: ["full_admin", 'data_management', 'customer_services',],
      permission_name: ["backoffice.customer_service"],
      children: [
        {
          name: 'Policies',
          url: '/customer-service/policies',
          icon: 'icon-list',
          permission: ["full_admin", 'data_management', 'customer_services',],
          permission_name: ["policy.views.backend.policy.PolicyViewSet.list.get"],
        },
        {
          name: 'Claims',
          url: '/customer-service/claims',
          icon: 'icon-list',
          permission: ["full_admin", 'data_management', 'customer_services',],
          permission_name: ["claim.views.backend.claim.ClaimViewSet.list.get"],
        },
        {
          name: 'Complaints',
          url: '/customer-service/complaints',
          icon: 'icon-list',
          permission: ["full_admin", 'data_management', 'customer_services',],
          permission_name: ["complaint.views.backend.complaint.ComplaintBackendViewSet.list.get"],
        },
        {
          name: 'Search',
          url: '/customer-service/search',
          icon: 'icon-magnifier',
          permission: ["full_admin", 'data_management', 'customer_services'],
          permission_name: [
            "policy.views.backend.policy.PolicyViewSet.list.get",
            "claim.views.backend.claim.ClaimViewSet.list.get",
            "complaint.views.backend.complaint.ComplaintBackendViewSet.list.get",
          ],
        },
      ]
    },
    {
      name: 'Users',
      url: '/users',
      icon: 'icon-user',
      permission: ["full_admin", 'data_management',],
      permission_name: ["user.views.backend.users.UserViewSet.list.get"],
    },
    {
      name: 'Customers',
      url: '/customers',
      icon: 'icon-user',
      permission: ["full_admin", 'data_management',],
      permission_name: ["user.views.backend.customer.CustomerViewSet.list.get"],
    },
    {
      name: 'Vets',
      url: '/vets',
      icon: 'icon-user',
      permission: ["full_admin", 'data_management',],
      permission_name: ["user.views.backend.vet.VetViewSet.list.get"],
    },
    {
      name: 'Tasks',
      url: '/tasks',
      icon: 'icon-list',
      permission: ["full_admin", 'data_management',],
      children: [
        {
          name: 'Tasks',
          url: '/tasks',
          icon: 'icon-list',
          permission: ["full_admin", 'data_management',],
          permission_name: ["claim.views.backend.task.TaskViewSet.list.get"],
        },
        {
          name: 'My Tasks',
          url: '/tasks/my/tasks',
          icon: 'icon-list',
          permission: ["full_admin", 'data_management',],
          permission_name: ["claim.views.backend.task.TastViewSet.mytasks.get"],
        },
      ]
    },
    {
      name: 'Payments',
      url: '/payments',
      icon: 'icon-credit-card',
      permission: ["full_admin", 'data_management',],
      permission_name: ["payment.views.PaymentViewSet.list.get"],
    },
    {
      name: 'Reporting',
      url: '/reporting',
      icon: 'icon-chart',
      permission: ["full_admin", 'data_management', 'customer_services',],
      children: [
        {
          name: 'Export',
          url: '/reporting/export',
          icon: 'icon-drawer',
          permission: ["full_admin", 'data_management',],
          permission_name: ["reporting.views.ReportingViewSet.standard.get"],
        },
        {
          name: 'Import',
          url: '/reporting/import',
          icon: 'icon-drawer',
          permission: ["full_admin", 'data_management',],
          permission_name: [
            "reporting.views.ReportingViewSet.import_file.get",
            "reporting.views.ReportingViewSet.import_file.create"
          ],
        },
      ]
    },
    {
      name: 'Config',
      url: '/system-config',
      icon: 'icon-settings',
      permission: ["full_admin", 'data_management', 'customer_services',],
      children: [
        {
          name: 'Products',
          url: '/system-config/products',
          icon: 'icon-list',
          permission: ["full_admin", 'data_management',],
          permission_name: ["core.views.product.ProductViewSet.list.get"],
        },
        {
          name: 'Documents',
          url: '/system-config/documents',
          icon: 'icon-doc',
          permission: ["full_admin", 'data_management',],
          permission_name: ["core.views.document.DocumentAPIView.list.get"],
        },
        {
          name: 'Claim Documents',
          url: '/system-config/claim-documents',
          icon: 'icon-doc',
          permission: ["full_admin", 'data_management',],
          permission_name: ["core.views.claim_document.ClaimDocumentAPIView.list.get"],
        },
        {
          name: 'Claim Exclusions',
          url: '/system-config/exclusions',
          icon: 'icon-list',
          permission: ["full_admin", 'data_management',],
          permission_name: ["core.views.exclusion.ClaimExclusionAPIView.list.get"],
        },
        {
          name: 'Letters Suite',
          url: '/system-config/letter-suite',
          icon: 'icon-doc',
          permission: ["full_admin", 'data_management',],
          permission_name: ["correspondence.views.backend.LetterSuiteAPIView.list.get"],
        },
        {
          name: 'Task Due Dates',
          url: '/system-config/task',
          icon: 'icon-list',
          permission: ["full_admin", 'data_management',],
          permission_name: ["claim.views.backend.task.TaskDueDateViewSet.list.get"],
        },
        {
          name: 'Questions',
          url: '/system-config/questionsections',
          icon: 'icon-list',
          permission: ["full_admin", 'data_management',],
          permission_name: ["core.views.product.ProductViewSet.list.get"],
        },
        {
          name: 'Portal Popups',
          url: '/system-config/portal/popups',
          icon: 'icon-list',
          permission: ["full_admin", 'data_management',],
          permission_name: ["core.views.product.ProductViewSet.list.get"],
        },
        {
          name: 'Portal Pages',
          url: '/system-config/portal/pages',
          icon: 'icon-list',
          permission: ["full_admin", 'data_management',],
          permission_name: ["core.views.product.ProductViewSet.list.get"],
        },
        {
          name: 'Portal Styling',
          url: '/system-config/portal/styles',
          icon: 'icon-list',
          permission: ["full_admin", 'data_management',],
          permission_name: ["core.views.product.ProductViewSet.list.get"],
        },
      ]
    }
  ]
}
