<template>
  <div class="p-2 ml-1 mb-3" v-if="displayOrHide">
    <b-form-checkbox v-model="accepting_calls" name="check-button" switch @change="AgentAccepting">
      <p>Accepting Calls?</p>
    </b-form-checkbox>
    <div id="call_display_window" v-if="accepting_calls" class="pt-2">

      <a v-b-tooltip:hover.bottom v-bind:title="agentStatusTitle">
        <font-awesome-icon :icon="agentStatusIcon" border fixed-width size="2x" v-bind:class="agentStatusClass"></font-awesome-icon>
      </a>

      <a v-b-tooltip:hover.bottom v-bind:title="callStatusTitle">
        <font-awesome-icon :icon="callStatusIcon" border fixed-width size="2x" v-bind:class="callStatusClass"></font-awesome-icon>
      </a>

      <span class="ml-2 align-top">{{ call_status }}</span>

      <div class="mt-2">
        <div v-if="lcd_display === null" class="lcd_wrapper mr-2 pr-2 pl-2 mb-2"><p class="lcd align-middle text-right h3" style="color: #036506;">JOINEDUP</p></div>
        <div v-if="lcd_display !== null" class="lcd_wrapper mr-2 pr-2 pl-2 mb-2"><p class="lcd align-middle text-right h3 text-uppercase">{{ lcdScreen }}</p></div>

        <a v-b-tooltip:hover.bottom v-bind:title="yesButtonTooltipText" @click="YesButton">
          <font-awesome-icon :icon="yesButtonIcon" border fixed-width size="2x" v-bind:class="yesButtonClass"></font-awesome-icon>
        </a>

        <a v-b-tooltip:hover.bottom v-bind:title="noButtonTooltipText" @click="NoButton">
          <font-awesome-icon :icon="noButtonIcon" border fixed-width size="2x" v-bind:class="noButtonClass"></font-awesome-icon>
        </a>

        <a v-b-tooltip:hover.bottom v-bind:title="muteButtonTooltipText" @click="MuteButton">
          <font-awesome-icon :icon="muteButtonIcon"  border fixed-width size="2x" v-bind:class="muteButtonClass"></font-awesome-icon>
        </a>

        <!--<a id="keypad_button" v-b-tooltip:hover.bottom v-bind:title="keypadButtonTooltipText" @click="KeypadButton">
          <font-awesome-icon :icon="keypadButtonIcon"  border fixed-width size="2x" v-bind:class="keypadButtonClass"></font-awesome-icon>
        </a>-->

        <a id="keypad_button" @click="KeypadButton">
          <font-awesome-icon :icon="keypadButtonIcon"  border fixed-width size="2x" v-bind:class="keypadButtonClass"></font-awesome-icon>
        </a>

        <b-popover target="keypad_button" placement="bottomleft" v-bind:title="keypadButtonTooltipText" :show.sync="show_keypad_popover">
          <table>
            <tr>
              <td><b-button class="m-2 px-3 py-2 font-weight-bold" pill variant="info" @click="HandleKeypadButton"><span class="h4">7</span></b-button></td>
              <td><b-button class="m-2 px-3 py-2 font-weight-bold" pill variant="info" @click="HandleKeypadButton"><span class="h4">8</span></b-button></td>
              <td><b-button class="m-2 px-3 py-2 font-weight-bold" pill variant="info" @click="HandleKeypadButton"><span class="h4">9</span></b-button></td>
            </tr>
            <tr>
              <td><b-button class="m-2 px-3 py-2 font-weight-bold" pill variant="info" @click="HandleKeypadButton"><span class="h4">4</span></b-button></td>
              <td><b-button class="m-2 px-3 py-2 font-weight-bold" pill variant="info" @click="HandleKeypadButton"><span class="h4">5</span></b-button></td>
              <td><b-button class="m-2 px-3 py-2 font-weight-bold" pill variant="info" @click="HandleKeypadButton"><span class="h4">6</span></b-button></td>
            </tr>
            <tr>
              <td><b-button class="m-2 px-3 py-2 font-weight-bold" pill variant="info" @click="HandleKeypadButton"><span class="h4">1</span></b-button></td>
              <td><b-button class="m-2 px-3 py-2 font-weight-bold" pill variant="info" @click="HandleKeypadButton"><span class="h4">2</span></b-button></td>
              <td><b-button class="m-2 px-3 py-2 font-weight-bold" pill variant="info" @click="HandleKeypadButton"><span class="h4">3</span></b-button></td>
            </tr>
            <tr>
              <td><b-button class="m-2 px-3 py-2 font-weight-bold" pill variant="info" @click="HandleKeypadButton"><span class="h4">*</span></b-button></td>
              <td><b-button class="m-2 px-3 py-2 font-weight-bold" pill variant="info" @click="HandleKeypadButton"><span class="h4">0</span></b-button></td>
              <td><b-button class="m-2 px-3 py-2 font-weight-bold" pill variant="info" @click="HandleKeypadButton"><span class="h4">#</span></b-button></td>
            </tr>
            <tr>
              <td><b-button class="m-2 px-1 py-2 font-weight-bold" pill v-bind:variant="keypadButtonDialClass" @click="HandleKeypadButtonDial">
                  <font-awesome-icon icon="phone-square-alt" fixed-width size="2x"></font-awesome-icon>
              </b-button></td>
              <td>&nbsp;</td>
              <td><b-button class="m-2 px-1 py-2 font-weight-bold" pill v-bind:variant="keypadButtonDeleteClass" @click="HandleKeypadButtonDelete">
                  <font-awesome-icon icon="backspace" fixed-width size="2x"></font-awesome-icon>
              </b-button></td>
            </tr>
          </table>
        </b-popover>

      </div>
    </div>
    <b-modal id="screenpop_modal" size="xl" title="Jump To Location" v-model="show_screenpop_modal">
      <div class="pb-4" v-if="screenpop_data">
        Incoming Call From: {{ screenpop_number }}
        <b-button v-if="customer_entered_number" variant="danger">
          Customer Number: {{ customer_entered_number }}<br/>
          CUSTOMER ENTERED THIS NUMBER: VERIFY IDENTITY!
        </b-button>
        <br/>
        Customers Found: {{ screenpop_data.length }}
      </div>
      <div>
        <b-card no-body>
          <b-tabs card>
            <b-tab v-for="(content, key) in screenpop_data" v-bind:key="key" v-bind:title="content.customer.name" active>
              <b-card-text>
                <div class="container">
                  <div class="row">
                    <div class="col-5">
                      <p class="font-weight-bold">Customer</p>
                      <b-button @click="PopCustomer(content.customer.id)">Customer {{ content.customer.id }}</b-button>
                      <p>
                        User ID: {{ content.customer.id }}<br/>
                        Username: {{ content.customer.username }}<br/>
                        D.O.B: {{ content.customer.dob }}<br/>        
                        Address: {{ content.customer.address }}<br/>   
                        Postcode: {{ content.customer.postcode }}<br/>
                        Telephone: {{ content.customer.telephone }}<br/>        
                        Mobile: {{ content.customer.mobile }}<br/>       
                        Work: {{ content.customer.work }}<br/>      
                        
                      </p>
                    </div>
                    <div class="col-7">
                    <p class="font-weight-bold">Claims</p>
                      <b-table sticky-header="140px" striped hover :items="content.claims" :fields="screenPopClaimFields" no-border-collapse>
                        <template #cell(go)="data">
                          <b-button @click="PopClaim(content.customer.id, data.value);">Claim {{ data.value }}</b-button>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div class="row">
                    <div class="col-5">
                      <p class="font-weight-bold">Policies</p>
                      <b-table sticky-header="140px" striped hover :items="content.policies" :fields="screenPopPolicyFields" no-border-collapse>
                        <template #cell(go)="data">
                          <b-button @click="PopPolicy(content.customer.id, data.value);">Policy {{ data.value }}</b-button>
                        </template>
                      </b-table>
                    </div>
                    <div class="col-7">
                      <p class="font-weight-bold">Complaints</p>
                      <b-table sticky-header="140px" striped hover :items="content.complaints" :fields="screenPopComplaintFields" no-border-collapse>
                        <template #cell(go)="data">
                          <b-button @click="PopComplaint(content.customer.id, data.value);">Complaint {{ data.value }}</b-button>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </b-modal>
  </div>
</template>
<script>

import Id from "bootstrap-vue/src/mixins/id";

import Callcentre from './../callcentre'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser, faTty, faPhone, faPhoneVolume, faBan, faMicrophoneAlt, faMicrophoneAltSlash, faGripVertical, faBackspace, faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons'
import { CALLCENTRE_OPTIONS } from './../store/state/callcentre'

library.add(faUser, faTty, faPhone, faPhoneVolume, faBan, faMicrophoneAlt, faMicrophoneAltSlash, faGripVertical, faBackspace, faPhoneSquareAlt);

export default {
  name: 'CallCentreDisplay',
  components: {
    Id,
  },
  data: () => {
    return CALLCENTRE_OPTIONS;
  },
  mounted (){
    window.addEventListener('vueEmit', this.VueEmit);
    Object.keys(CALLCENTRE_OPTIONS).forEach(key => {
      //console.log("MOUNTED", key, this.GetVariable(key))
      this.SetVariable(key, this.GetVariable(key));
    });
    var token = localStorage.getItem('accessToken');
    var options = {
      "debug": true,
      "ws_token": token,
      "ws_url": process.env.VUE_APP_ROOT_WS_URL,
    }
    window.callcentre = new Callcentre(options);
    window.callcentre.connect();
  },
  computed: {
    displayOrHide: function(){
      return true;
    },
    lcdScreen: function(){
      return this.lcd_display;
    },
    screenPopClaimFields: function(){
      return [
        {
          key: "id",
          sortable: true,
        },
        {
          key: "reference",
        },
        {
          key: "description",
        },
        {
          key: "created",
          sortable: true,
        },
        {
          key: "go",
        },
      ]
    },
    screenPopPolicyFields: function(){
      return [
        {
          key: "id",
          sortable: true,
        },
        {
          key: "reference",
        },
        {
          key: "created",
          sortable: true,
        },
        {
          key: "go",
        },
      ]
    },
    screenPopComplaintFields: function(){
      return [
        {
          key: "id",
          sortable: true,
        },
        {
          key: "reference",
        },
        {
          key: "description",
        },
        {
          key: "created",
          sortable: true,
        },
        {
          key: "go",
        },
      ]
    },
    yesButtonClass: function(){
      var inactive = true;
      if(this.call_status == "Ringing (Incoming)" || this.call_status == "Caller In Queue"){
        inactive = false;
      }
      return {
        'ml-2': true,
        'mr-4': true,
        'inactive': inactive,
        'callcentre_icon': true,
        'callcentre_green': true,
      }
    },
    yesButtonIcon: function(){
      return ['fa', 'phone'];
    },
    yesButtonTooltip(){
      if(this.call_status == "Ringing (Incoming)" || this.call_status == "Caller In Queue"){
        return true;
      }
      return false;
    },
    yesButtonTooltipText(){
      if(this.call_status == "Ringing (Incoming)" || this.call_status == "Caller In Queue"){
        return "Answer";
      } else {
        return '';
      }
    },
    noButtonClass: function(){
      var inactive = true;
      if(this.call_status == "Ringing (Incoming)" || this.call_status == "Call Active"){
        inactive = false;
      }
      return {
        'mr-4': true,
        'inactive': inactive,
        'callcentre_icon': true,
        'callcentre_red': true,
      }
    },
    noButtonIcon: function(){
      return ['fa', 'ban'];
    },
    noButtonTooltip(){
      if(this.call_status == "Ringing (Incoming)" || this.call_status == "Call Active"){
        return true;
      }
      return false;
    },
    noButtonTooltipText(){
      if(this.call_status == "Ringing (Incoming)"){
        return "Decline";
      } else if(this.call_status == "Call Active"){
        return "End Call";
      } else {
        return '';
      }
    },
    muteButtonClass: function(){
      var inactive = true;
      if(this.call_status == "Call Active"){
        inactive = false;
      }
      return {
        'mr-4': true,
        'inactive': inactive,
        'callcentre_icon': true,
        'callcentre_black': true,
      }
    },
    muteButtonIcon: function(){
      var icon = 'microphone-alt';
      if(this.call_muted){
        icon = 'microphone-alt-slash';
      }
      return ['fa', icon];
    },
    muteButtonTooltip(){
      if(this.call_status == "Call Active"){
        return true;
      }
      return false;
    },
    muteButtonTooltipText(){
      if(this.call_status == "Call Active"){
        if(this.call_muted){
          return "Un-Mute";
        } else {
          return "Mute";
        }
      } else {
        return '';
      }
    },
    keypadButtonClass: function(){
      var inactive = true;
      if(this.call_status == "Idle" || this.call_status == "Call Active"){
        inactive = false;
      }
      return {
        'inactive': inactive,
        'callcentre_icon': true,
        'callcentre_black': true,
      }
    },
    keypadButtonIcon: function(){
      return ['fa', 'grip-vertical'];
    },
    keypadButtonTooltip(){
      if(this.call_status == "Idle" || this.call_status == "Call Active"){
        return true;
      }
      return false;
    },
    keypadButtonTooltipText(){
      if(this.call_status == "Idle"){
        return "Keypad: Dial";
      } else if(this.call_status == "Call Active"){
        return "Keypad: DTMF";
      } else {
        return '';
      }
    },
    keypadButtonDialClass(){
      if(this.keypadCanDial){
        return "success";
      }
      return "secondary";
    },
    keypadButtonDeleteClass(){
      if(this.keypadCanDelete){
        return "danger";
      }
      return "secondary";
    },
    agentStatusTitle: function() {
      var str = "";
      if(this.subscribed_rooms){
        str = this.br + 'Subscribed to:' + this.br;
        str += Object.keys(this.subscribed_rooms).join(this.br);
      }
      return 'Agent Status: ' + this.agent_status + str;
    },
    agentStatusClass: function() {
      var red = false;
      var green = false;
      if(this.agent_status == 'Disconnected'){
        red = true;
        green = false;
      } else if(this.agent_status == 'Connected'){
        red = false;
        green = true;
      }
      return {
        callcentre_icon: true,
        callcentre_red: red,
        callcentre_green: green,
      }
    },
    agentStatusIcon: function(){
      return ['fa', 'user'];
    },
    callStatusTitle: function() {
      return 'Call Status: ' + this.call_status;
    },
    callStatusClass: function() {
      var red = false;
      var green = false;
      var ringing = false;
      if(this.call_status == 'Ringing (Incoming)' || this.call_status == 'Caller In Queue'){
        ringing = true;
      }
      if(this.call_status == 'Unavailable'){
        red = true;
        green = false;
      } else {
        red = false;
        green = true;
      }
      return {
        'ml-2': true,
        'shake': ringing,
        'shake-constant': ringing,
        callcentre_icon: true,
        callcentre_red: red,
        callcentre_green: green,
      }
    },
    callStatusIcon: function(){
      var icon = 'tty';
      if(this.call_status == 'Ringing (Incoming)' || this.call_status == 'Caller In Queue'){
        icon = 'phone-volume'
      }
      return ['fa', icon];
    },
    keypadCanDial: function(){
      if(/^01[0-9]{8,9}$/.test(this.number_to_dial)){
        return true;
      }
      if(/^07[0-9]{9}$/.test(this.number_to_dial)){
        return true;
      }
      return false;
    },
    keypadCanDelete: function(){
      if(this.number_to_dial){
        return true;
      }
      return false;
    }
  },
  methods: {
    AgentAccepting(){
      console.log("AgentAccepting", this.accepting_calls)
      if(this.accepting_calls){
        this.AgentDisconnect();
      } else {
        this.AgentConnect();
      }
    },
    AgentConnect() {
      this.SetVariable('accepting_calls', true);
      window.callcentre.acceptingCalls(true);
    },
    AgentDisconnect() {
      this.SetVariable('accepting_calls', false);
      window.callcentre.acceptingCalls(false);
    },
    YesButton(){
      if(this.call_status == "Ringing (Incoming)"){
        this.AnswerCall();
      } else if(this.call_status == "Caller In Queue"){
        this.GrabCallFromQueue();
      }
    },
    NoButton(){
      if(this.call_status == "Ringing (Incoming)"){
        this.HangupCall();
      } else if(this.call_status == "Call Active"){
        this.HangupCall();
      }
    },
    MuteButton(){
      if(this.call_status == "Call Active"){
        if(this.call_muted){
          this.MuteOff();
        } else {
          this.MuteOn();
        }
      }
    },
    KeypadButton(){
      if(this.show_keypad_popover){
        this.SetVariable('show_keypad_popover', false);
        this.SetVariable('number_to_dial', null);
        if(!window.callcentre.activeCall){
          this.SetLCDDisplay(null);
        }
      } else {
        this.SetVariable('show_keypad_popover', true);
      }
    },
    /*SetNumberToDial(number){
      this.SetLCDDisplay("DIAL: " + this.number_to_dial);
      
    },*/
    KeypadNumberToDialAddDigit(digit){
      if(this.number_to_dial === null){
        this.SetVariable('number_to_dial', digit);
      } else {
        this.SetVariable('number_to_dial', this.number_to_dial += digit);
      }
      this.SetLCDDisplay("DIAL: " + this.number_to_dial);
    },
    HandleKeypadButton(obj){
      var digit = obj.target.innerText;
      if(window.callcentre.activeCall){
        window.callcentre.dtmf(digit);
      } else {
        this.KeypadNumberToDialAddDigit(digit);
      }
    },
    HandleKeypadButtonDial(){
      if(this.keypadCanDial){
        var formatted = "+44" + this.number_to_dial.substring(1, 11)
        window.callcentre.dial(formatted);
        this.SetVariable('number_to_dial', null);
      }
    },
    HandleKeypadButtonDelete(){
      if(this.keypadCanDelete){
        if(this.number_to_dial.length == 1){
          this.SetVariable('number_to_dial', null);
          this.SetLCDDisplay(null);
        } else {
          this.SetVariable('number_to_dial', this.number_to_dial.slice(0, -1));
          this.SetLCDDisplay("DIAL: " + this.number_to_dial);
        }
      }
    },
    GrabCallFromQueue(){
      window.callcentre.dequeue();
    },
    AnswerCall() {
      window.callcentre.answer();
    },
    HangupCall() {
      window.callcentre.hangup();
    },
    MuteOn() {
      this.SetVariable('call_muted', true);
      window.callcentre.mute(true);
    },
    MuteOff() {
      this.SetVariable('call_muted', false);
      window.callcentre.mute(false);
    },
    SetVariable(name, value){
      console.log("SetVariable", name, value)
      if(this.$data.hasOwnProperty(name)){
        this.$data[name] = value;
        this.$store.commit("setCallcentreItem", {"name": name, "value": value})
      }
    },
    GetVariable(name){
      return this.$store.getters.getCallcentreItem(name);
    },
    SetAgentStatus(data){
      this.SetVariable("agent_status", data);
    },
    SetCallStatus(data){
      this.SetVariable("call_status", data);
    },
    SetCallerID(data){
      if(data === null){
        this.SetVariable("caller_id", data);
        this.SetLCDDisplay(this.caller_id);
      } else {
        this.SetVariable("customer_entered_number", data.customer_entered);
        this.SetVariable("caller_id", data.number);
        this.SetLCDDisplay(this.caller_id);
        this.SetVariable("screenpop_number", this.caller_id);
        this.ScreenPop(data.screenpop);
      }
    },
    SetLCDDisplay(data){
      this.SetVariable("lcd_display", data);
    },
    SetAcceptingCalls(data){
      this.SetVariable("accepting_calls", data);
    },
    SetSubscribedRooms(data){
      this.SetVariable("subscribed_rooms", data);
    },
    SetQueueName(data){
      this.SetVariable("queue_name", data);
      if(this.queue_name){
        this.SetLCDDisplay(this.queue_name.split('_').pop());
      } else {
        this.SetLCDDisplay(this.queue_name);
      }
    },
    ScreenPop(data){
      this.SetVariable("screenpop_data", data);
      this.ShowScreenpopModal(true);
    },
    PopClaim(customer_id, claim_id){
      this.AssignCorrespondenceRecord(customer_id, "claim", claim_id);
      this.ShowScreenpopModal(false);
      this.$router.push({"name": "Claim", "params": {"id": claim_id}})
    },
    PopComplaint(customer_id, complaint_id){
      this.AssignCorrespondenceRecord(customer_id, "complaint", complaint_id);
      this.ShowScreenpopModal(false);
      this.$router.push({"name": "Complaint", "params": {"id": complaint_id}})
    },
    PopPolicy(customer_id, policy_id){
      this.AssignCorrespondenceRecord(customer_id, "policy", policy_id);
      this.ShowScreenpopModal(false);
      this.$router.push({"name": "Policy", "params": {"id": policy_id}})
    },
    PopCustomer(customer_id){
      this.AssignCorrespondenceRecord(customer_id);
      this.ShowScreenpopModal(false);
      this.$router.push({"name": "Customer", "params": {"id": customer_id}})
    },
    AssignCorrespondenceRecord(customer_id, type=null, object_id=null){
      this.SetVariable("screenpop_selected_customer", customer_id);
      window.callcentre.correspondence(this.screenpop_selected_customer, type, object_id);
    },
    ShowScreenpopModal(status){
      this.SetVariable("show_screenpop_modal", status);
    },
    VueEmit(data){
      console.log(data);
      var event = data.detail.event;
      var obj_data = data.detail.data;
      if(event == 'agentStateCallback'){
        this.SetAgentStatus(obj_data);
      } else if(event == 'callStateCallback'){
        this.SetCallStatus(obj_data);
      } else if(event == 'callerIDCallback'){
        this.SetCallerID(obj_data);
      } else if(event == 'acceptingCallsCallback'){
        this.SetAcceptingCalls(obj_data);
      } else if(event == 'subscribedRoomsCallback'){
        this.SetSubscribedRooms(obj_data);
      } else if(event == 'queueNameCallback'){
        this.SetQueueName(obj_data);
      } else if(event == 'popClaim'){
        this.PopClaim(obj_data);
      }
    }
  }
}
</script>
