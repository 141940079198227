<template>
  <div class="app">

    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <b-link class="navbar-brand" to="#">
        <img class="navbar-brand-full" src="../assets/img/logo.png" width="140" height="35" alt="JoinedUp Logo">
        <img class="navbar-brand-minimized" src="../assets/img/logo-small.png" width="35" height="35" alt="JoinedUp Logo">
      </b-link>
      <SidebarToggler class="d-md-down-none" display="lg" :defaultOpen=true />
      <b-navbar-nav class="d-md-down-none">
        <b-nav-item class="px-3" to="/dashboard">Dashboard</b-nav-item>
        <!--<b-nav-item class="px-3" to="/customer-service" exact>Customer Service</b-nav-item>
        <b-nav-item class="px-3" to="/users" exact>Users</b-nav-item>-->
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">

        <!--<b-nav-form class="mr-3">
          <label for="" class="small mr-2">Product/Area: </label>
          <b-form-select size="sm" class="mr-sm-2" v-model="area" :options="products" @change="AreaChange"></b-form-select>
        </b-nav-form>-->

        <!--
        <b-nav-item class="d-md-down-none">
          <DefaultHeaderDropdownNotif/>
        </b-nav-item>
        <b-nav-item class="d-md-down-none">
          <DefaultHeaderDropdownTasks/>
        </b-nav-item>
        <b-nav-item class="d-md-down-none">
          <DefaultHeaderDropdownMssgs/>
        </b-nav-item>

        -->
        <!--<b-nav-text id="agent_status" class="mr-3">Agent Status: <span>Disconnected</span></b-nav-text>
        <b-nav-text id="call_status" class="mr-3">Call Status: <span>Unavailable</span></b-nav-text>
        <b-nav-text id="caller_id" class="mr-3">Caller ID: <span></span></b-nav-text>

        <b-nav-item class="d-md-down-none mr-4">
          <CallCentreKeypad/>
        </b-nav-item>

        <b-nav-item class="d-md-down-none mr-4">
          <CallCentreOptions/>
        </b-nav-item>-->

        <DefaultHeaderDropdownAccnt/>

      </b-navbar-nav>

      <!--<AsideToggler class="d-none d-lg-block" />
      <AsideToggler class="d-lg-none" mobile />-->
    </AppHeader>
    <div class="app-body">
        <AppSidebar fixed>
          <keep-alive include="CallCentreDisplay">
            <CallCentreDisplay></CallCentreDisplay>
          </keep-alive>
          <SidebarHeader/>
          <SidebarForm/>
          <SidebarNav :navItems="navItems"></SidebarNav>
          <SidebarFooter/>
          <SidebarMinimizer/>
        </AppSidebar>
      
      <main class="main">
        <Breadcrumb :list="list"/> <!-- class="mb-0" -->

    <!--<b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Where would you like to go?"
      v-model="area_modal"
    >
      <form ref="form">
        <b-form-group
          label="Please select what part of the system you'd like to use"
          label-for="area-input"
          invalid-feedback="This field is required"
        >
          <b-form-select
            id="area-input"
            v-model="area"
            :options="products"
            required
            @change="AreaChange"
          ></b-form-select>
        </b-form-group>
      </form>
    </b-modal>-->

        <!--
        <ol class="breadcrumb mt-0 bg-light">
          <li class="breadcrumb-item">Household</li>
          <li class="breadcrumb-item">AGEAS</li>
          <li class="breadcrumb-item">All Tables</li>
        </ol>
        -->
        <!--
        <div class="row">
        <div class="col-8 mr-0 pr-0">
          <Breadcrumb :list="list"/>
        </div>
        <div class="col-4 bg-white" style="border-bottom: solid #c8ced3; border-radius: 0; border-bottom-width:1px; margin-bottom:24px;">
          <a href="" class="btn btn-sm btn-outline-success mt-2 float-right mr-3">Add Addon</a>
        </div>
          </div>
          -->

        <div class="container-fluid">

          <b-alert
            :show="dismissCountDown"
            :variant="alert_type"
            @dismissed="dismissAlert"
            @dismiss-count-down="countDownChanged"
          >
            <i v-if="alert_type === 'danger' || alert_type === 'warning'" class="fa fa-exclamation-triangle mr-2"></i>
            <i v-if="alert_type === 'success'" class="fa fa-check mr-2"></i>
            <span class="pb-2">{{ alert_text }}</span><b-button-close @click="hideAlert" class="pull-right"></b-button-close>
            <b-progress
              :variant="alert_type"
              :max="dismissSecs"
              :value="dismissCountDown"
              height="4px"
              style="margin-top: 15px;"
            ></b-progress>
          </b-alert>

          <router-view :key="$route.fullPath"></router-view>
        </div>
      </main>
      <AppAside fixed>
        <!--aside-->
        <DefaultAside/>
      </AppAside>
    </div>
    <TheFooter>
      <!--footer-->
      <div>
        <a target="_blank" href="https://joinedup.io">Insurance As A Service</a>
        <span class="ml-1">&copy; {{ new Date().getFullYear() }} JoinedUp.</span>
      </div>
      <div class="ml-auto">
        <span class="mr-1">Powered by</span>
        <a target="_blank" href="https://joinedup.io">JoinedUp</a>
      </div>
    </TheFooter>
  </div>
</template>


<script>
  import nav from '@/_nav'
  import system_nav from '@/_config_nav'
  import { Header as AppHeader, SidebarToggler, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav, Aside as AppAside, AsideToggler, Footer as TheFooter, Breadcrumb } from '@coreui/vue'
  import DefaultAside from './DefaultAside'
  import DefaultHeaderDropdown from './DefaultHeaderDropdown'
  import DefaultHeaderDropdownNotif from './DefaultHeaderDropdownNotif'
  import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt'
  import DefaultHeaderDropdownMssgs from './DefaultHeaderDropdownMssgs'
  import DefaultHeaderDropdownTasks from './DefaultHeaderDropdownTasks'
  /*import CallCentreKeypad from './CallCentreKeypad'
  import CallCentreOptions from './CallCentreOptions'*/
  import CallCentreDisplay from './CallCentreDisplay'
  import { BModal, BButton } from 'bootstrap-vue'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'DefaultContainer',
    components: {
      AsideToggler,
      AppHeader,
      AppSidebar,
      AppAside,
      TheFooter,
      Breadcrumb,
      DefaultAside,
      DefaultHeaderDropdown,
      DefaultHeaderDropdownMssgs,
      DefaultHeaderDropdownNotif,
      DefaultHeaderDropdownTasks,
      DefaultHeaderDropdownAccnt,
      SidebarForm,
      SidebarFooter,
      SidebarToggler,
      SidebarHeader,
      SidebarNav,
      SidebarMinimizer,
      BModal,
      BButton,
      CallCentreDisplay,
      /*CallCentreKeypad,
      CallCentreOptions*/
    },
    data () {
      return {
        area: localStorage.getItem('selected_product'),
        area_modal: false, //!localStorage.getItem('selected_product'),
        nav: nav.items,
        navSystem: system_nav.items,
        dismissSecs: 5,
        dismissCountDown: 0,
        showDismissibleAlert: true,
        show_alert: false,
        selected_product: localStorage.getItem('selected_product'),
        options: {
          areas: [
            {value: 'sc', text: 'System Configuration'},
            {value: 'ns', text: 'Non Standard'},
            {value: 'bc', text: 'Household'},
            {value: 'btl', text: 'Buy To Let'},
            {value: 'bs', text: 'Buildings Society'}
          ]
        }
      }
    },
    watch: {
      show_alert: function(newval, oldval){
        if(newval){
          this.dismissCountDown = this.alert_time
          this.dismissSecs = this.alert_time
        } else {
          this.dismissCountDown = 0
        }
      },
      alert_show: function(newval, oldval){
        this.show_alert = newval
        //this.showAlert()
      }
    },
    methods: {
      ...mapActions([
        'setDashCancel',
      ]),
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown;
      },
      hideAlert(){
        console.log("HIDE DASH ALERT")
        this.setDashCancel(true);
        this.dismissAlert();
      },
      dismissAlert(){
        console.log("DISMISS DASH ALERT")
        this.$store.commit('unsetGlobalAlert');
      },
      showAlert(object=null){
        this.dismissCountDown = 0;
        if(this.alert_show){
          this.dismissCountDown = this.alert_timer;
        } else if(object){
          this.$store.commit('setGlobalAlert', object);
        } else {
          this.dismissCountDown = 0
          this.$store.commit('unsetGlobalAlert')
        }

      },
    },
    computed: {
        ...mapGetters({
        alert_show: 'getGlobalAlert',
        alert_type: 'getGlobalAlertType',
        alert_text: 'getGlobalAlertText',
        alert_time: 'getGlobalAlertTime',
        alert_timeout: 'getGlobalAlertTimeout',
        dash_cancel: 'getDashCancel',
      }),
      name () {
        return this.$route.name
      },
      list () {
        let routes = this.$route.matched.filter((route) => route.name || route.meta.label )
        return routes
      },
      /*products() {

        let hardcoded = [];
        let products = []; //JSON.parse(localStorage.getItem('products'));
        let user_permissions = localStorage.getItem('user_permissions')

        if (products === null ||user_permissions === null ) {
          this.$store.dispatch('logout');
        }

        if (JSON.parse(user_permissions).includes('full_admin')) {
          hardcoded.push({value: '', text: 'Customer Service'})
          hardcoded.push({value: 'system', text: 'System Configuration'})
        }

        let response = []

        if (hardcoded) {
          response = [{value: '', text: 'Please Select...'}, ...hardcoded, ...products];
        } else {
          response = [{value: '', text: 'Please Select...'}, ...products];
        }

        return response
      },*/
      navItems(){
        let list = this.nav

        if (this.area === 'system') {
          list = this.navSystem
        }

        let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))

        return list.filter(function (item) {

          if (item.permission) {
            for (var permission in user_permissions) {
              if (item.permission.includes(user_permissions[permission])) {
                return item
              }
            }
          }

        });
      },
    }
  }
</script>
